* {
  margin: 0;
  padding: 0;
}

.banner {
  display: block;
  border-top-right-radius: 0.45rem !important;
  border-top-left-radius: 0.45rem !important;
}
.rounded-3 {
  border-top-color: white;
  /* border-top-width: 0px !important ; */
  border-top-style: none;
}

/* button css */
.mb-1 {
  padding: 7px;
  width: 100%;
  font-size: 15px;
  border: 0px;
  margin-bottom: 30px !important;
}
#start,
#proceed {
  background-color: #4285f4;
  margin-top: 5%;
  color: white;
  margin-bottom: 1.5rem !important;
  padding: 2.25px !important;
}

/* view details css*/
.tag p {
  color: #4285f4;
  display: block;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: flex-end;
  margin-bottom: -4px;
  margin-right: 0.8rem;
  margin-top: 0.5rem;
  font-size: 14px;
}

/* Features & Details css */
.p-md-4 p {
  font-family: "Times New Roman", Times, serif;
  font-size: 16px !important;
  font-weight: 500;
  color: #05204c;
  position: relative;
  margin-left: -0.9rem;
  margin-bottom: 20px;
  margin-top: -4px;
}

.p-md-4 p::after {
  content: ""; /* Required for the pseudo-element to appear */
  display: block;
  width: 15%; /* Extend the underline across the entire width of the container */
  height: 2px; /* Adjust the thickness of the underline as needed */
  background-color: #4285f4; /* Change the color of the underline */
  position: absolute;
  bottom: -1px; /* Adjust the distance between text and underline as needed */
  left: 0;
  margin-top: 4px !important;
}

.onholtext {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  height: 100vh; 
}

.invalidUrl ,.failureDiv {
  text-align: center; 
}

.errImg , .failureImg {
  display: inline-block; 
  vertical-align: middle; 
}

.invalidurlmessage ,.failureMsg {
  display: inline-block; 
  vertical-align: middle; 
  margin-top: 11px;
}

.input-name {
  display: flex;
  align-items: center;
}
/* +91 */
.countrycode {
  width: 3rem !important;
  /* background-color: #e8ebe7; */
}

#code::placeholder {
  color: #838d9c !important;
}
#code {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-right: 0px !important ;
  background-color: #e8ebe7;
}

.countrycode input[type="text"] {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: 1px solid rgba(113, 128, 150, 0.5) !important;
  border-right: 0px !important;
}

#mobileNumber {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
/* mobile and pincode css */
input[type="tel"] {
  width: 100% !important;
  padding: 5.26px !important;
  border-radius: 6px !important;
  color: black !important;
  border: 1px solid rgba(113, 128, 150, 0.5) !important;
  outline: none !important;
}

/* name and email css */
input[type="text"],
input[type="email"] {
  width: 100% !important;
  padding: 6px !important;
  border-radius: 6px !important;
  color: black !important;
  border: 1px solid rgba(113, 128, 150, 0.5) !important;
  background-color: white !important;
  margin-top: 1px !important;
  outline: none !important;
}

label {
  font-size: 14px;
  color: #718096 !important;
}

#fullname {
  margin-bottom: -0.4rem !important;
}

/* vertical gap css */
#div1 {
  margin-bottom: 1rem !important;
}
#div2 {
  margin-bottom: 0.6rem !important;
}
#div3 {
  margin-bottom: 0.5rem !important;
}
#div4 {
  margin-bottom: 1rem !important;
}

/* errors css*/
#namingerror {
  margin-top: 0.37rem !important;
}

.error-message {
  color: red;
  font-size: 12px !important;
  margin-top: 5px;
  margin-top: -0.1rem !important;
}

/* Style for the asterisk */
.error-star {
  color: red;
  font-size: 10px;
}

span.text {
  font-weight: 300;
  font-size: 14px !important;
  color: rgb(42, 41, 41);
  margin-bottom: 2px;
  margin-top: 2px;
}

.mb-4 {
  margin-bottom: -1px;
}

.form-label {
  margin-bottom: -1.5rem !important;
}

.p-md-4 {
  /* height: auto;  */
  margin-top: 10px; /*margin betwenn banner and list or input field*/
}

.container {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* Position the error message below the form field */
.error-message::before {
  content: ""; /* Create an empty content */
  position: absolute; /* Position absolutely */
  top: 100%; /* Position below the form field */
  left: 0; /* Align with the left of the form field */
  width: 100%; /* Take the full width of the container */
}

/* checkbox css */

.checkbox-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}

.checkbox-container input {
  margin-top: 2px; /* Adjust this value as needed to align the checkbox */
}
.me-2 {
  outline-style: none;
}

/* list css */
li {
  margin-bottom: 10px;
  display: flex;
}

.list {
  width: 32ex !important;
}

.list li {
  width: 100% !important;
  display: flex;
  align-items: flex-start;
}

.bullet-image {
  width: 0.95rem;
  height: auto;
  vertical-align: middle; /* Change vertical alignment to middle */
  margin-left: -1rem;
  margin-right: 0.3rem;
  margin-top: -0.2rem;
}

/* footer css */
.fixed-footer {
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
  margin-bottom: -6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
  background-color: #05204c;
}

/* Style the footer text */
.footer p {
  color: white;
  margin: auto;
  font-size: 14px;
}

span#companyname {
  color: #4285f4;
}

.pincode-input-container {
  position: relative;
}

.pincode-input-container input[type="tel"] {
  width: 100%;
  padding-right: 30px; 
}


.pincode-spinner {
  
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 10px; 
  background-color: #fff; 
  background-color: rgba(255, 255, 255, 0.1); 
}